body {
  font-family: "Roboto", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-size: 16px;
  margin: 16px;
}

div {
  display: flex;
  flex-direction: column;
}

.container {
  width: 100%;
  height: calc(100vh-32);
}

.card {
  padding: 16px;
  border: 2px solid #3f51b5;
  border-radius: 10px;
  align-items: center;
}

.card header {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

.card .action {
  margin-top: 32px;
  align-items: center;
}

.card .action span {
  font-size: 32px;
  color: #3f51b5;
}

.loader {
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  margin: 16px;
  visibility: hidden;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
